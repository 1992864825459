import { __decorate } from "tslib";
// Watch
import { Component, Vue } from 'vue-property-decorator';
import { livroStore, mainStore, reposicaoStore, salaStore, } from '@/store';
import SaveButton from '@/components/SaveButton.vue';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import AtualizarLinkAulaComponent from '@/components/AtualizarLinkAulaComponent.vue';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
import { newReposicao } from '@/interfaces/reposicao';
import _ from 'lodash';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => {
        if (typeof value === 'number' && value >= 0) {
            return true;
        }
        return !!value;
    },
});
let Create = class Create extends Vue {
    constructor() {
        super(...arguments);
        this.id = null;
        this.entityObject = {};
        this.search = '';
        this.selectedItem = {};
        this.selectedProfessorDaTurma = null;
        this.selectedProfessorDaAula = null;
        this.atividades = [];
        this.turmasAlunos = [];
        this.selectedTurmaAluno = null;
        this.professores = [];
        this.alunosProfessor = [];
        this.alunosReposicao = [];
        this.aulaDate = null;
        this.aulaTime = null;
        this.loading = false;
    }
    get getStatus() {
        switch (this.entityObject.status) {
            case 0:
                return 'Aula não lecionada';
            case 1:
                return 'Realizada';
            case 2:
                return 'Aula lecionada';
        }
    }
    get existAlunoCobrarReposicao() {
        for (const item of this.alunosReposicao) {
            if (item.cobrar_reposicao) {
                return true;
            }
        }
    }
    get salas() {
        return salaStore.salas;
    }
    get getLivro() {
        if (this.entityObject.atividade) {
            const livro = _.find(this.livros, (l) => l.id === this.entityObject.atividade.livro_id);
            return `${livro?.nome} - ${livro.edicao}`;
        }
        return null;
    }
    get getSala() {
        if (this.entityObject.sala) {
            const sala = _.find(this.salas, (s) => s.id === this.entityObject.sala_id);
            return `${sala?.nome}`;
        }
        return null;
    }
    get livros() {
        return livroStore.livros;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    async mounted() {
        this.loading = true;
        await salaStore.getSalas();
        await livroStore.getLivros();
        // @ts-ignore
        if (this.$router.currentRoute.params.id) {
            // @ts-ignore
            this.id = parseInt(this.$router.currentRoute.params.id, 0);
            this.entityObject = await reposicaoStore.getReposicao(this.id);
            // @ts-ignore
            const data = new Date(this.entityObject.data_hora);
            if (this.entityObject.data_hora) {
                const tempData = this.entityObject.data_hora.split('T')[0].split('-');
                this.aulaDate = `${tempData[2]}/${tempData[1]}/${tempData[0]}`;
                // @ts-ignore
                this.aulaTime = this.formatTime(data);
            }
            this.selectedProfessorDaAula = this.entityObject.professor;
            this.alunosReposicao = await reposicaoStore.getReposicaoAlunos(this.entityObject.id);
            // @ts-ignore
        }
        else if (this.$router.currentRoute.params.id_turma) {
            // @ts-ignore
            this.id = parseInt(this.$router.currentRoute.params.id_turma, 0);
            this.entityObject = await reposicaoStore.getReposicoesByTurmaAtividade(this.id);
            const tempData = this.entityObject.data.split('-');
            this.aulaDate = `${tempData[2]}/${tempData[1]}/${tempData[0]}`;
            this.aulaTime = this.entityObject.hora;
            this.selectedProfessorDaAula = this.entityObject.professor;
        }
        else {
            this.entityObject = newReposicao();
        }
        this.loading = false;
    }
};
Create = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
            SaveButton,
            DateFieldComponent,
            AtualizarLinkAulaComponent,
        },
    })
], Create);
export default Create;
